import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import Person2Icon from '@mui/icons-material/Person2'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HistoryTwoToneIcon from '@mui/icons-material/HistoryTwoTone';
import BackupIcon from '@mui/icons-material/Backup';
import LibraryMusicTwoToneIcon from '@mui/icons-material/LibraryMusicTwoTone';
import SyncIcon from '@mui/icons-material/Sync';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DownloadIcon from '@mui/icons-material/Download';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PremiumIcon from '../../assets/premium.png'

export default function NavBarItems() {

  const location = useLocation();

  const isSelected = (key) => {
    if (key === "library" && location.pathname.startsWith("/dashboard/accounts")) {
      return true
    }
    if (key === "sync" && location.pathname.startsWith("/dashboard/sync")) {
      return true
    }
    if (key === "import" && location.pathname.startsWith("/dashboard/import")) {
      return true
    }
    if (key === "history" && location.pathname.startsWith("/dashboard/transferHistory")) {
      return true
    }
    if (key === "profile" && location.pathname.startsWith("/dashboard/profile")) {
      return true
    }
    if (key === "backups" && location.pathname.startsWith("/dashboard/backups")) {
      return true
    }
    if (key === "premium" && location.pathname.startsWith("/dashboard/premium")) {
      return true
    }
    if (key === "logout" && location.pathname.startsWith("/dashboard/logout")) {
      return true
    }
    return false;
  }


  return (
    <React.Fragment>

      <ListItemButton
        selected={isSelected("library")}
        component={Link}
        to={"/dashboard/accounts"}
      >
        <ListItemIcon>
          <LibraryMusicTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Connected Accounts" />
      </ListItemButton>

      {/* <ListItemButton
        selected={isSelected("sync")}
        component={Link}
        to={"/dashboard/sync"}
      >
        <ListItemIcon>
          <SyncIcon />
        </ListItemIcon>
        <ListItemText primary="Sync" />
      </ListItemButton> */}


      {/* <ListItemButton
        selected={isSelected("import")}
        component={Link}
        to={"/dashboard/import"}
      >
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText primary="Import" />
      </ListItemButton> */}


      <ListItemButton
        selected={isSelected("history")}
        component={Link}
        to={"/dashboard/transferHistory"}>
        <ListItemIcon>
          <HistoryTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Transfer History" />
      </ListItemButton>

      {/*     
        <ListItemButton 
        selected={isSelected("backups")}
        component={Link}
        to={"/dashboard/backups"}>
          <ListItemIcon>
            <BackupIcon />
          </ListItemIcon>
          <ListItemText primary="Library Backups" />
        </ListItemButton> */}

      <Divider sx={{ my: 1 }} />

      <ListSubheader component="div" inset>
        Account
      </ListSubheader>

       {/* <ListItemButton 
        selected={isSelected("premium")}
        component={Link}
        style={{background: "blue", borderRadius: "25% 10%"}}
        to={"/dashboard/premium"}>
          <ListItemIcon>
            <WorkspacePremiumIcon style={{fill: "#FFD700"}}/>
          </ListItemIcon>
          <ListItemText primary="Premium" style={{color: "#FFD700", fontWeight: "bold"}}/>
        </ListItemButton>  */}

      <ListItemButton
        selected={isSelected("profile")}
        component={Link}
        to={"/dashboard/profile"}>
        <ListItemIcon>
          <Person2Icon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItemButton>


      <ListItemButton
        component="a"
        href="https://musicporter.com/contact-us/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemIcon>
          <ConnectWithoutContactIcon />
        </ListItemIcon>
        <ListItemText primary="Contact us" />
      </ListItemButton>

      <ListItemButton
        selected={isSelected("logout")}
        component={Link}
        to={"/dashboard/logout"}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>

    </React.Fragment>
  )
}
